import Vue from 'vue'
import moment from 'moment'
import { formatSeconds, formatCode } from './tool'

// Vue.filter('typeFilter', function (value) {
//     switch (value) {
//         case '':
//             return "";
//     }
// })

//时间格式过滤器
/**
 * moment 时间格式支持：
 * YYYY 1970 1971 ... 2029 2030
 * MM  01 02 ... 11 12
 * DD  01 02 ... 30 31
 * HH  00 01 ... 22 23
 * mm  00 01 ... 58 59
 * ss  00 01 ... 58 59
 * 更多 参照官网 http://momentjs.cn/docs/#/displaying/
 */
Vue.filter('formatTimeFilter', function (value, format, todayDesc) {
    if(todayDesc && moment(new Date()).format(format) === moment(value).format(format)) {
       return todayDesc
    }

    //处理星期展示 统一展示成周几
    const weekDescArr = [
        { en: 'Sun', zh: '周日' },
        { en: 'Mon', zh: '周一' },
        { en: 'Tue', zh: '周二' },
        { en: 'Wed', zh: '周三' },
        { en: 'Thu', zh: '周四' },
        { en: 'Fri', zh: '周五' },
        { en: 'Sat', zh: '周六' }
    ]
    const weekIdx = moment(value).days()
    const { en, zh } = weekDescArr[weekIdx]
    return value ? moment(value).format(format).replace(en, zh) : ''
})

//会议号格式过滤器 如："XXX XXX XXX"
Vue.filter('formatCodeFilter', function (confNo, fmtChar, maxLen) {
    return formatCode(confNo, fmtChar, maxLen)
})


//会议持续时长 格式化过滤器 XX小时XX分钟
Vue.filter('formatDurationFilter', function (start, end, hourUnit, minuteUnit) {
    const seconds = moment(end).diff(moment(start),'seconds')
    return formatSeconds(seconds, 
        {
            show: true,
            isPadZero: false,
            unit: hourUnit
        },
        {
            show: true,
            isPadZero: false,
            unit: minuteUnit
        },
        {
            show: false
        }
    )
})


//文字头像处理
Vue.filter('nameFirstFilter', function (value) {
   return value ? value.charAt() : ''
})
